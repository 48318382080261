<template>
  <el-row justify="center">
    <el-col>
      <ul class="art-list">
        <li>
          <el-row type="flex" justify="space-between">
            <el-col :span="15">
              GO 编程模式：K8S VISITOR 模式
            </el-col>
            <el-col :span="8">
              <el-row type="flex" justify="end">
                <i class="el-icon-date"/>
                <span class="icon-after">2020年12月26日</span>
                <i class="el-icon-user"/>
                <span class="icon-after">陈  皓 </span>
                <i class="el-icon-view"/>
                <span class="icon-after">48,113 人阅读</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </li>
        <li>
          <el-row type="flex" justify="space-between">
            <el-col :span="15">
              GO 编程模式：K8S VISITOR 模式
            </el-col>
            <el-col :span="8">
              <el-row type="flex" justify="end">
                <i class="el-icon-date"/>
                <span class="icon-after">2020年12月26日</span>
                <i class="el-icon-user"/>
                <span class="icon-after">陈  皓 </span>
                <i class="el-icon-view"/>
                <span class="icon-after">48,113 人阅读</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </li>
        <li>
          <el-row type="flex" justify="space-between">
            <el-col :span="15">
              GO 编程模式：K8S VISITOR 模式
            </el-col>
            <el-col :span="8">
              <el-row type="flex" justify="end">
                <i class="el-icon-date"/>
                <span class="icon-after">2020年12月26日</span>
                <i class="el-icon-user"/>
                <span class="icon-after">陈  皓 </span>
                <i class="el-icon-view"/>
                <span class="icon-after">48,113 人阅读</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </li>
        <li>
          <el-row type="flex" justify="space-between">
            <el-col :span="15">
              GO 编程模式：K8S VISITOR 模式
            </el-col>
            <el-col :span="8">
              <el-row type="flex" justify="end">
                <i class="el-icon-date"/>
                <span class="icon-after">2020年12月26日</span>
                <i class="el-icon-user"/>
                <span class="icon-after">陈  皓 </span>
                <i class="el-icon-view"/>
                <span class="icon-after">48,113 人阅读</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </li>
        <li>
          <el-row type="flex" justify="space-between">
            <el-col :span="15">
              GO 编程模式：K8S VISITOR 模式
            </el-col>
            <el-col :span="8">
              <el-row type="flex" justify="end">
                <i class="el-icon-date"/>
                <span class="icon-after">2020年12月26日</span>
                <i class="el-icon-user"/>
                <span class="icon-after">陈  皓 </span>
                <i class="el-icon-view"/>
                <span class="icon-after">48,113 人阅读</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </li>
        <li>
          <el-row type="flex" justify="space-between">
            <el-col :span="15">
              GO 编程模式：K8S VISITOR 模式
            </el-col>
            <el-col :span="8">
              <el-row type="flex" justify="end">
                <i class="el-icon-date"/>
                <span class="icon-after">2020年12月26日</span>
                <i class="el-icon-user"/>
                <span class="icon-after">陈  皓 </span>
                <i class="el-icon-view"/>
                <span class="icon-after">48,113 人阅读</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </li>
        <li>
          <el-row type="flex" justify="space-between">
            <el-col :span="15">
              GO 编程模式：K8S VISITOR 模式
            </el-col>
            <el-col :span="8">
              <el-row type="flex" justify="end">
                <i class="el-icon-date"/>
                <span class="icon-after">2020年12月26日</span>
                <i class="el-icon-user"/>
                <span class="icon-after">陈  皓 </span>
                <i class="el-icon-view"/>
                <span class="icon-after">48,113 人阅读</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </li>
        <li>
          <el-row type="flex" justify="space-between">
            <el-col :span="15">
              GO 编程模式：K8S VISITOR 模式
            </el-col>
            <el-col :span="8">
              <el-row type="flex" justify="end">
                <i class="el-icon-date"/>
                <span class="icon-after">2020年12月26日</span>
                <i class="el-icon-user"/>
                <span class="icon-after">陈  皓 </span>
                <i class="el-icon-view"/>
                <span class="icon-after">48,113 人阅读</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </li>
        <li>
          <el-row type="flex" justify="space-between">
            <el-col :span="15">
              GO 编程模式：K8S VISITOR 模式
            </el-col>
            <el-col :span="8">
              <el-row type="flex" justify="end">
                <i class="el-icon-date"/>
                <span class="icon-after">2020年12月26日</span>
                <i class="el-icon-user"/>
                <span class="icon-after">陈  皓 </span>
                <i class="el-icon-view"/>
                <span class="icon-after">48,113 人阅读</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </li>
        <li>
          <el-row type="flex" justify="space-between">
            <el-col :span="15">
              GO 编程模式：K8S VISITOR 模式
            </el-col>
            <el-col :span="8">
              <el-row type="flex" justify="end">
                <i class="el-icon-date"/>
                <span class="icon-after">2020年12月26日</span>
                <i class="el-icon-user"/>
                <span class="icon-after">陈  皓 </span>
                <i class="el-icon-view"/>
                <span class="icon-after">48,113 人阅读</span>
              </el-row>
            </el-col>
          </el-row>
          <el-divider></el-divider>
        </li>
      </ul>
    </el-col>
    <el-col>
      <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="1"
          :page-size="10"
          :pager-count="9"
          :total="1000"
      >
      </el-pagination>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name:'ArtList'
}
</script>

