<template>
  <el-row type="flex" justify="center" class="list-page">
    <el-col :span="17" class="left-side">
      <el-row class="list-box">
        <ArtList/>
      </el-row>
    </el-col>
    <el-col :span="5" class="right-side">
      <el-row class="tag-box">
        <el-col class="tags">
          <Tags/>
        </el-col>
      </el-row>
      <el-row class="hot-box">
        <el-col class="tops">
          <TopList/>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<style lang="scss">
@import "../styles/list.scss";
</style>

<script>
import ArtList from "@/components/ArtList.vue";
import Tags from "@/components/Tags.vue";
import TopList from "@/components/TopList.vue";

export default {
  name: 'List',
  components: {
    ArtList, Tags, TopList
  }
}
</script>
